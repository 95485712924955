import { useMemo } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

export type AppRoutePath = `/photogray-app${RoutePath}`;

export type RoutePath =
  | '/'
  | '/app-download-redirect.html'
  | '/home'
  | '/home/info'
  | '/home/reward'
  | '/home/history/stamp'
  | '/home/history/coupon'
  | '/story'
  | `/story/${string}`
  | `/4cut-ranking/game/category${string}`
  | `/4cut-ranking/game/play${string}`
  | `/4cut-ranking/game/winner${string}`
  | '/4cut-ranking/apply/agreement'
  | '/4cut-ranking/apply/certificate'
  | '/4cut-ranking/apply/category'
  | '/4cut-ranking/apply/photo-card-name'
  | '/4cut-ranking/apply/photo'
  | `/4cut-ranking/apply/complete/${string}`
  | '/ranking'
  | '/search'
  | `/photo-card/${string}`
  | '/profile/me'
  | '/profile/edit'
  | `/profile/${string}`
  | `/profile-photo-cards/${string}`
  | '/setting'
  | '/setting/account'
  | '/setting/help'
  | `/setting/certificate${string}`
  | '/notice'
  | `/notice/${string}`
  | '/push-history'
  | '/withdraw'
  | '/terms-of-use'
  | '/privacy-policy'
  | '/privacy-policy-agreement'
  | '/terms/app/terms-of-use'
  | '/terms/app/privacy-policy'
  | '/terms/app/privacy-policy-agreement'
  | '/terms/app/marketing-agreement'
  | `/login${string}`
  | '/sign-up/step1'
  | '/sign-up/step2'
  | '/sign-up/step3'
  | '/start'
  | '/space'
  | '/space/game';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

interface Return
  extends Omit<NextRouter, 'replace' | 'back' | 'push' | 'query'> {
  replace(
    path: RoutePath | { query: {} },
    as?: URL,
    options?: TransitionOptions,
  ): void;
  back(): void;
  push(path: RoutePath): void;
  query: NextParsedUrlQuery;
}

const useNavigator = (): Return => {
  const router = useRouter();
  return useMemo(() => {
    return {
      ...router,
      back() {
        router.back();
      },
      push(path: RoutePath) {
        if (router.pathname.startsWith('/photogray-app')) {
          router.push(`/photogray-app${path}`);
        } else {
          router.push(path);
        }
      },
      replace(path: RoutePath | { query: {} }, as, options) {
        if (router.pathname.startsWith('/photogray-app')) {
          router.replace(
            typeof path === 'string' ? `/photogray-app${path}` : path,
            as,
            options,
          );
        } else {
          router.replace(path, as, options);
        }
      },
      query: router.query,
    };
  }, [router]);
};
export default useNavigator;
